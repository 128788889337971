import React, {useState, useEffect} from 'react'
import CrestronRow from './CrestronRow'
import './MrsCrestronStyles.css'


const MrsCrestron = () => {
  const [state, setState] = useState({
    crestrons: [],
    sortType: "id",
    sortReversed: false
  });

  useEffect(() => {
    window.g_socket.emit('build_crestron_table');

    //listeners
    window.g_socket.on('one_crestron', (crestron) => {
      setState( (prev) => ({
          crestrons: [...prev.crestrons, crestron]
        })
      );
    });

    return () => {
      window.g_socket.removeAllListeners('one_crestron');
    }
  },[]);

  const updateCrestron = (cres) => {
    const updateCrestrons = [...state.crestrons];
    const index = state.crestrons.map(c => c.id).indexOf(cres.id);
    updateCrestrons[index] = cres;
    setState({...state, crestrons: updateCrestrons});
  };

  const setSort = (type) => {
    if(state.sortType !== type) {
      setState({sortReversed:false, sortType: type});
    } else {
      setState({sortReversed: !state.sortReversed, sortType: type});
    }
    sortList();
  };

  const sortList = () => {
    const sortedCrestrons = [...state.crestrons];
    sortedCrestrons.sort((a,b) => compareRows(a,b, state.sortType, state.sortReversed));
    setState({...state , crestrons: sortedCrestrons});
  };

  const compareRows = (a,b, sortType, sortReversed) => {
    let lhs, rhs;
    if(sortType === 'shortname') {
      lhs = a.shortname + ' ' + a.room;
      rhs = b.shortname + ' ' + b.room;
    } else {
      lhs = a[sortType] || a.state[sortType];
      rhs = b[sortType] || b.state[sortType];
      if(lhs === undefined) return sortReversed ? -1:1;
      else if(rhs === undefined) return sortReversed ? 1:-1;
    }
    if(sortReversed) return lhs < rhs ? 1:-1;
    else return lhs < rhs ? -1:1;
  };

  return (
    <div className="rooms-container">
      <div className="mrs-crestron">
        <h1>CAEN Crestron Emulator</h1>
        <table>
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th onClick={()=>setSort("id")}>ID</th>
              <th onClick={()=>setSort("shortname")}>Name</th>
              <th onClick={()=>setSort("port")}>Port</th>
              <th onClick={()=>setSort("philview_version")}>Version</th>
              <th onClick={()=>setSort("signal")}>Signal</th>
              <th onClick={()=>setSort("no_sig_sec")}>Time Since No Signal</th>
              <th onClick={()=>setSort("date")}>Date</th>
              <th onClick={()=>setSort("time")}>Time</th>
              <th onClick={()=>setSort("audio_healthy")}>Audio Healthy</th>
              <th onClick={()=>setSort("proj1_installed")}>Projector 1 Installed</th>
              <th onClick={()=>setSort("proj1_pwr")}>Projector 1 Power</th>
              <th onClick={()=>setSort("proj1_source")}>Projector 1 Source</th>
              <th onClick={()=>setSort("proj1_lamp_hours")}>Projector 1 Lamp Hours</th>
              <th onClick={()=>setSort("proj1_healthy")}>Projector 1 Healthy</th>
              <th onClick={()=>setSort("proj2_installed")}>Projector 2 Installed</th>
              <th onClick={()=>setSort("proj2_pwr")}>Projector 2 Power</th>
              <th onClick={()=>setSort("proj2_source")}>Projector 2 Source</th>
              <th onClick={()=>setSort("proj2_lamp_hours")}>Projector 2 Lamp Hours</th>
              <th onClick={()=>setSort("proj2_healthy")}>Projector 2 Healthy</th>
              <th onClick={()=>setSort("user_problem")}>User Problem</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            { state.crestrons.map((c) => <CrestronRow crestron={c} key={c.id} updateCrestron={updateCrestron}/>) }
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default MrsCrestron