import React from 'react';
import Roombox from '../RoomMenu/Roombox';
import Projectors from './Projectors';
import LectureCapture from './LectureCapture';
import RoomDetails from './RoomDetails';
import LiveRoomCamera from './LiveRoomCamera';
import IOSScreenshot from './IOSScreenshot';
import UserProblem from './UserProblem';
import IOSRoomControl from './IOSRoomControl';
import Roomlog from './Roomlog';
import Calendar from './Calendar';
import { connect } from 'react-redux';
import { withRouter } from '../../helperFunctions/withRouter';
import { setModal } from '../../helperFunctions/actions';
import { Navigate } from 'react-router-dom';
import RoomNotes from './RoomNotes';

//wraps RoomInfo to find if the URL supplied is a valid room (redirects if not)
export function checkValidRoomInfoURL(WrappedComponent) {
  const SelectivelyDisplayedComponent = (props) => {

    // check if this is a valid room
    const parsedURL = props.location.pathname.split('/');
    // parsedURL should be something like ["", "room", "25"] where 25 is room ID
    const roomid = parsedURL[2];
    if(isNaN(roomid) || !props.rooms[roomid]) {
      console.error("room " + roomid + " does not exist");
      return <Navigate to="/" replace />
    } else {
      // valid room, pass down that ID to the RoomInfo component
      return <WrappedComponent roomid={roomid} />
    } 
    
  };

  const mapStateToProps = (state) => ({ rooms: state.rooms });
  return connect(mapStateToProps)(SelectivelyDisplayedComponent);
}


// Page for information about a specific room
// Wraps the info-displaying components, supplies them with info from socket events and props
// NOTE: this component is rendered from the Higher-Order Component above  which checks if the URL
//  points to a valid room. 
//  IF it is a valid room, it renders this component with the prop 'roomid'
//  which mapStateToProps uses to connect() it to props.room

class RoomInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      JSONreceived: false,
      viewingUsers: [] //received from the socket event 'users'
    }
    this.requestDetails = this.requestDetails.bind(this);
  }

  requestDetails(tries) { 
    if(!this.state.JSONreceived) {

      if(tries > 0) {
        window.g_socket.emit('details', this.props.room.id);
        setTimeout(() => this.requestDetails(tries - 1), 500);

      } else {
        this.props.setModal({
          shown: true,
          header: "There's been a disconnect.",
          paragraph: "We can't connect to this room right now - try reloading the page.",
          buttonText: "Reload",
          onClose:() => window.location.reload()
        });
      }

    } 
  }

  componentDidMount() {

    this.requestDetails(5); //try 5 socket emits

    window.g_socket.on('users', (data) => {
      this.setState({viewingUsers:data})
    })
    window.g_socket.on('detailsJSON', (room) => {
      let baseUrl = '';

      if (window.location.href.indexOf(':3000') > 0) {
        baseUrl = 'https://itech-test.engin.umich.edu:8443';
      }

      this.setState( {
        webcam: room.webcam,
        prob_note: room.prob_note,
        calendarURLs: {
          events: baseUrl + room.eventURL,
          av: baseUrl + '/api/avevents/' + room.id,// AV events in a room - projectors and crestrons
          wixel: baseUrl+ '/api/wixelevents/' + room.id // Wixel events in a room - microphones
        },
        leccap: room.leccap, 
        projectorsInstalled: room.projectorsInstalled,
        chargingBasesInstalled: room.chargingBasesInstalled,
        room_notes: room.room_notes,
        JSONreceived: true //let the components know they can render, stop asking the server for data
      })
      // prevent the page from shunting the user to the bottom
      window.scrollTo(0,0);

    })
  }
  componentWillUnmount() {
    window.g_socket.removeAllListeners('users');
    window.g_socket.removeAllListeners('detailsJSON');
    // let the server know we're leaving the room
    // so it can update who's viewing it and move us out
    window.g_socket.emit('leaving-room', this.props.room.id);
  }

  componentDidUpdate() {
    //rebuild tooltips in case new data-tips are showing
    // Tooltip.rebuild();
  }

  render() {
    // check if we have the data
    if(!this.state.JSONreceived) return <i className="fa fa-spinner fa-pulse"></i>;

    //all's well, render the page
    const { room } = this.props;
    const { viewingUsers, webcam, leccap, projectorsInstalled, prob_note, chargingBasesInstalled, room_notes } = this.state;
    return (
      <div className="roominfo-container">

        <div className="row center-xs room-header"> 
            <h1 className="room-name">
              {room.name}
            </h1>
            <div className="viewing-users">
              <p className="viewing-label">Viewing this room:</p>
              {viewingUsers.map((uniqname, i) => <p className="user-label" key={i}>{uniqname}</p>)}
            </div>
        </div>
        

        <div className="room-elements">
          <div className="row between-xs">
              <div className="col-xs-3" id="left-column">
                <LiveRoomCamera room={room} webcam={webcam}/>
                {room.screenshot && <IOSScreenshot room={room} screenshot={room.screenshot} />}
                <Roombox roomid={room.id} imgSrc="none"/>
                {room.user_problem > 0 && <UserProblem room={room} prob_note={prob_note} />}
                <Projectors room={room} projectorsInstalled={projectorsInstalled} />
                {room.display_data && <IOSRoomControl display_data={room.display_data} sources={room.sources} room={room} />}
                <LectureCapture leccapData={leccap} />
                <RoomDetails/>
                <RoomNotes roomid={room.id} room_notes={room_notes}/>
              </div>

              <div className="col-xs-9" id="right-column">
                <div className="weather-graph"></div>
                <Calendar apiURLs={this.state.calendarURLs} chargingBasesInstalled={chargingBasesInstalled} projectorsInstalled={projectorsInstalled}/>
                <Roomlog room={room} />
              </div>

          </div>
        </div>

      </div>
    );
  }
}



function mapStateToProps(state, ownProps) {
  if(state.dataReceived) {
    return {
      room: state.rooms[ownProps.roomid]
    }
  }
}
const mapDispatchToActions = { setModal };
export default withRouter(connect(mapStateToProps, mapDispatchToActions)(RoomInfo))