import React from 'react';
import { Route, Navigate, Routes, useParams } from 'react-router-dom';
import { connect, useStore } from 'react-redux';
import { withRouter } from '../../helperFunctions/withRouter';
import WaitingScreen from './WaitingScreen';
import Modal from '../Reusable/Modal';
import Topbar from '../Bars/Topbar';
import Sidebar from '../Bars/Sidebar';
import RoomMenu from '../RoomMenu/RoomMenu';
import RoomInfo from '../RoomInfo/RoomInfo';
import CalendarGrid from '../CalendarGrid/CalendarGrid';
import MicReport from '../MicReport/MicReport';
import MrsCrestron from '../MrsCrestron/MrsCrestron';
import ImageQuery from '../ImageQuery/ImageQuery';
import SystemSettings from '../SystemSettings/SystemSettings';
import WelcomePage from './WelcomePage';

const ValidRoom = () => {
  const { id } = useParams();
  const rooms = useStore().getState().rooms;
  if(isNaN(id) || !rooms[id]) {
    console.error("room " + id + " does not exist");
    return <Navigate to="/" replace />;
  }
  return <RoomInfo roomid={id} />;
}

function ProtectedRoutes(props) {
  if (props.isLoggedIn) {
    if (!props.dataReceived) {
      return <WaitingScreen text="Loading..." />
    } else {
      const modalGrayOut = props.modal.shown ? "gray-out" : "";
      return (
        <>
          <Modal />
          <div id="confirm-box-root"></div>
          <div className={modalGrayOut}>
            <Topbar />
            <div className="main-container-flex" id="container-main">
              <Sidebar />
              <Routes>
                <Route exact path='/' element={<RoomMenu/>}/>
                {/* checkValidRoomInfoURL() to redirect back to home if it's an invalid room URL */}
                <Route path='/room/:id' element={<ValidRoom/>} />
                <Route exact path="/calendargrid" element={<CalendarGrid/>} />
                <Route exact path="/micreport" element={<MicReport/>} />
                <Route exact path="/mrs-crestron" element={<MrsCrestron/>} />
                <Route path="/viewimages/*" element={<ImageQuery/>} />
                <Route exact path="/settings" element={<SystemSettings/>}/>
                <Route path='*' element={<Navigate to="/" replace/>} />
              </Routes>
            </div>
          </div>
        </>
      )
    }
  } else {
    return (
      <WelcomePage />
    )
  }
}

function mapStateToProps(state) {
  return {
    isLoggedIn: state.isLoggedIn,
    dataReceived: state.dataReceived,
    modal: state.modal
  }
}

export default withRouter(connect(mapStateToProps)(ProtectedRoutes));