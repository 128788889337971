import Cookies from 'universal-cookie';
const cookies = new Cookies(null, {path:'/'});

export function setReduxState(data) {
  return {
    type: 'SET_DATA',
    payload: data
  }
}

export function toggleCookie(name) {
  return {
    type: 'TOGGLE_COOKIE',
    payload: name
  }
}

export function updateShownRooms() {
  return {
    type: 'UPDATE_SHOWN_ROOMS'
  }
}
export function updateRoomTypes() {
  return {
    type: 'UPDATE_ROOM_TYPES'
  }
}
export function updateRoomStatus() {
  return {
    type: 'UPDATE_ROOM_STATUS'
  }
}
export function updateActiveFilters(filters) {
  return {
    type: 'UPDATE_ACTIVE_FILTERS',
    payload: filters
  }
}
export function addInitialRoomsArray(rooms) {
  return {
    type:'ADD_ALL_ROOMS', 
    payload: rooms}
}
export function updateRoom(data) {
  return {
    type: 'UPDATE_ROOM',
    payload: data
  }
}

export function logout() {
  cookies.addChangeListener( cookie => {
    if(cookie.name === "token" && cookie.value === undefined) {
      //once the token is gone, redirect to Google accounts to sign them out
      // (so they don't instantly sign back in when they refresh the page)
      window.location.href = "https://accounts.google.com/logout";
    }
  });

  cookies.remove('token', {path: "/", secure: true})

  return {
    type: 'SET_DATA',
    payload: { isLoggedIn: false }
  }
}
export function login(userInfo) {
  return {
    type: 'SET_DATA',
    payload: {...userInfo, isLoggedIn:true}
  }
}

export function setModal(modal) {
  return {
    type: 'SET_DATA',
    payload: { modal }
  }
}

export function killServer(emitToServer = true) {
  window.g_socket.emit('kill');
  return {
    type: 'SERVER_DIED'
  }
}


export function sendCommand(command, options) { // optionalUsername is for when the tech response button
  if(command === "tech_response") {
    window.g_socket.emit("tech_response", options.id, options.techResUsername);
  } else {
    window.g_socket.emit('send_command', { command, ...options} )
  }

  //the below doesn't actually do anything, the reducer doesn't recognize the action
  return {
    type: 'SEND_COMMAND',
    payload: null
  }
};