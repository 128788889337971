import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import './RoomboxStyles.css'

function AVErrorRows({ room }) {
  let res = [];

  if (room.proj1_installed && !room.proj1_healthy) {
    res.push(
      <div key={room.id} className="row box-contents orangebg-lt">
        <div className="col-xs-12">Projector 1 - Error</div>
      </div>
    );
  }

  if (room.proj2_installed && !room.proj2_healthy) {
    res.push(
      <div key={room.id} className="row box-contents orangebg-lt">
        <div className="col-xs-12">Projector 2 - Error</div>
      </div>
    );
  }

  if (!room.audio_healthy) {
    res.push(
      <div key={room.id} className="row box-contents orangebg-lt">
        <div className="col-xs-12">Audio - Error</div>
      </div>
    );
  }

  if (res.length > 0) {
    return res;
  }

  return null;
}

// Microphone data about charging bases with 0 - 2 rows
function MicRows({ room, darkMode }) {

  //if no mics installed, return nothing.
  if (!room.akg_base_installed) return null;

  let mic_data = null;
  if (room.akg_base_data) {
    mic_data = JSON.parse(room.akg_base_data);
  }

  let number_mics_expected = 2;
  if (mic_data && mic_data.Device && mic_data.Device.number_mics_expected) {
    number_mics_expected = mic_data.Device.number_mics_expected;
  }

  let res = [];
  if (room.akg_connected && mic_data && mic_data.Device && mic_data.Slots && (number_mics_expected > 0) && mic_data.Device) {
    let chargingIcons = mic_data.Slots.map((slot, i) => {
      let slot_content = <div></div>
      if (slot.batt_state.readabe !== 'Empty') {
        slot_content = <table className='mic-details'>
          <caption>{room.name}</caption>
          <tbody>
            <tr>
              <th>State:</th>
              <td>{slot.batt_state.readable}</td>
            </tr>
            <tr>
              <th>Charge:</th>
              <td>{slot.batt_charge.readable}</td>
            </tr>
            <tr>
              <th>Current Capacity:</th>
              <td>{slot.batt_current_capacity.readable}</td>
            </tr>
            <tr>
              <th>Current Capacity Max:</th>
              <td>{slot.batt_current_capacity_max.readable}</td>
            </tr>
            <tr>
              <th>Battery Capacity:</th>
              <td>{slot.batt_capacity_max.readable}</td>
            </tr>
            <tr>
              <th>Health:</th>
              <td>{slot.batt_health.readable}</td>
            </tr>
            <tr>
              <th>Cycles:</th>
              <td>{slot.batt_cycle.readable}</td>
            </tr>
            <tr>
              <th>Error:</th>
              <td>{slot.batt_error.readable}</td>
            </tr>
            <tr>
              <th>Temperature:</th>
              <td>{slot.batt_temp_f.readable}</td>
            </tr>
            <tr>
              <th>Time to Full:</th>
              <td>{slot.batt_time_to_full.readable}</td>
            </tr>
            <tr>
              <th>Firmware Version:</th>
              <td>{mic_data.Device.fw_ver.readable}</td>
            </tr>
            <tr>
              <th>Model:</th>
              <td>{mic_data.Device.model.readable}</td>
            </tr>
          </tbody>
        </table>
      }

      let icon = <div></div>;
      switch (slot.batt_state.readable) {
        case 'Empty':
          icon = <i key={i} className="fa fa-circle" data-tooltip-id="tooltip" data-tooltip-content="Empty"></i>;
          return icon;
        case 'Charged':
          icon = <i className="fa fa-check-circle"></i>;
          break;
        case 'Charging':
          icon = <i className="fa fa-bolt"></i>;
          break;
        case 'Checking':
          icon = <i className="fa fa-clock-o"></i>;
          break;
        case 'Disconnected':
          console.log('Mic is disconnected', i);
          icon = <i className="fa fa-certificate"></i>;
          break;
        case 'Error':
          icon = <i className="fa fa-certificate"></i>;
          break;
        case 'Unknown':
        default:
          icon = <i className="fa fa-question-circle"></i>;
      }
      if (darkMode) {
        return <Popup key={i} trigger={icon} keepTooltipInside={true} className='darkmode-popup'>
          {slot_content}
        </Popup>
      }
      return <Popup key={i} trigger={icon} keepTooltipInside={true}>
        {slot_content}
      </Popup>
    });

    let index = 0;

    let micsExpected = (index === 0) &&
      `${number_mics_expected} mic${number_mics_expected === 1 ? '' : 's'} expected`;
    res.push(
      <div key={index} className="row box-contents">
        <div className="col-xs-2 center-xs">
          <p>Mics</p>
        </div>
        <div className="col-xs-5">
          {chargingIcons}
        </div>
        <div className="col-xs-5 center-xs">
          <p>{micsExpected}</p>
        </div>
      </div>
    );
  } else {
    //ERROR HANDLING
    if (!room.akg_connected) {
      res.push(
        <div key={-1} className="row box-contents">
          <div className="col-xs-2 center-xs">Mic</div>
          <div className="col-xs-10 center-xs">
            Disconnected from Mic Monitoring
          </div>
        </div>
      )
    } else if (!mic_data || !mic_data.Device || !mic_data.Slots) {
      res.push(
        <div key={-1} className="row box-contents">
          <div className="col-xs-2 center-xs">Mic</div>
          <div className="col-xs-10 center-xs">
            Data Error
          </div>
        </div>
      )
    }
  }

  return res;
}

function SourceIcon({ source, power }) {
  // If projector is off, don't display a source icon
  if (!power) {
    return null;
  }

  if (typeof source !== 'string') {
    return <i className="fa fa-question-circle" data-tooltip-id="tooltip" data-tooltip-content={source}></i>
  }
  let formattedSource = source.toLowerCase();
  if (formattedSource.includes("laptop") || formattedSource.includes("hdmi")) { //could be laptop 1 or 2
    return <i className="fa fa-laptop" data-tooltip-id="tooltip" data-tooltip-content={source}></i>
  } else if (formattedSource === "doc cam") {
    return <i className="fa fa-pencil-square-o" data-tooltip-id="tooltip" data-tooltip-content="Document Camera"></i>
  } else if (formattedSource.includes("imac") || formattedSource.includes("desktop") || formattedSource.includes("zoom") || formattedSource === 'pc') { //could be desktop 1 or 2
    return <i className="fa fa-desktop" data-tooltip-id="tooltip" data-tooltip-content={source}></i>
  } else if (formattedSource.includes("apple")) {
    return <i className="fa fa-apple" style={{ fontSize: 17 }} data-tooltip-id="tooltip" data-tooltip-content={formattedSource.replace('apple tv', 'Apple TV')}></i>
  } else if (formattedSource === "off") {
    return null;
  } else if (formattedSource.includes("no show") || formattedSource.includes("blank")) {
    return <i className="fa fa-eye-slash" data-tooltip-id="tooltip" data-tooltip-content={source} />;
  } else if (formattedSource === "") {
    return <i className="fa fa-question-circle" data-tooltip-id="tooltip" data-tooltip-content={"Unknown"} />;
  } else {
    return <i className="fa fa-question-circle" data-tooltip-id="tooltip" data-tooltip-content={source}></i>
  }
}

// the rows of iOS data
function IOSRows({ room }) {
  return room.display_data.map((display) => {
    let title = display.type + ' ' + display.id;
    return (
      <div className="row box-contents" key={display.id}>
        <div className="col-xs-4 center-xs">{title}</div>
        <div className="col-xs-2 center-xs">
          {display.power ? (<i className="fa fa-circle green" data-tooltip-id="tooltip" data-tooltip-content="On"></i>) : (<i className="fa fa-square red" data-tooltip-content="Off"></i>)}
        </div>
        <div className="col-xs-2 center-xs">
          <SourceIcon source={display.showing} power={display.power} />
        </div>
      </div>
    )
  });
}
// A row with data for projector 1 or 2, as specified by its props
function ProjectorRow({ room, number }) {
  let p = {};
  if (number === 1) {
    p = {
      healthy: room.proj1_healthy,
      installed: room.proj1_installed,
      lamp_hours: room.proj1_lamp_hours,
      pwr: room.proj1_pwr,
      source: room.proj1_source,
      rowClass: "row box-contents"
    };
  } else {
    p = {
      healthy: room.proj2_healthy,
      installed: room.proj2_installed,
      lamp_hours: room.proj2_lamp_hours,
      pwr: room.proj2_pwr,
      source: room.proj2_source,
      rowClass: "row box-contents"
    }
  }
  //if not installed, render nothing.
  if (!p.installed) return null;

  //get the progress bar's color and width
  let progressClassName = "progress-bar";
  const percent = (p.lamp_hours / room.max_lamp_hours) * 100
  if (percent < 75) {
    progressClassName += " light-bluebg" /* original (blue) */
  } else if (percent < 96) {
    progressClassName += " yellowbg" /* warning (yellow)*/
  } else {
    progressClassName += " redbg" /* error (red)*/
  }
  let display_percent = (percent > 100) ? 100 : percent;
  //set the powerIcon to green if (p.pwr), red otherwise
  let powerIcon = p.pwr ? (<i className="fa fa-circle green" data-tooltip-id="tooltip" data-tooltip-content="On"></i>) : (<i className="fa fa-square red" data-tooltip-id="tooltip" data-tooltip-content="Off"></i>)
  return (
    <div key={number} className={p.rowClass}>
      <div className="col-xs-2 center-xs">
        <p>Proj {number}</p>
      </div>
      {p.healthy
        ? <>
          <div className="col-xs-2 center-xs">
            {powerIcon}
          </div>
          <div className="col-xs-2 center-xs">
            <SourceIcon source={p.source} power={p.pwr} />
          </div>
          <div className="col-xs-6 center-xs">
            <div className="projector" data-tooltip-id="tooltip" data-tooltip-html={p.lamp_hours + " hours used <br/ > Maximum: " + room.max_lamp_hours + " hours"}>
              <div className={progressClassName} style={{ width: display_percent + '%' }}></div>
              <p className="projector-hours">{p.lamp_hours} h</p>
            </div>
          </div>
        </>
        : <>
          <div className="col-xs-10 center-xs">Not Working</div>
        </>
      }
    </div>
  )
}

// The camera livestream provided the imgSrc URL
function RoomCamera({ imgSrc }) {
  const [error, setError] = useState(false);
  const [loadedYet, setLoadedYet] = useState(false);
  const [prevImgSrc, setPrevImgSrc] = useState(imgSrc);
  useEffect(() => {
    if (imgSrc !== prevImgSrc) {
      if (loadedYet || prevImgSrc === 'none' || imgSrc === 'none') {
        setLoadedYet(false);
        setPrevImgSrc(imgSrc);
      }
    }
  }, [imgSrc, loadedYet, prevImgSrc]);

  function handleLoad() {
    setLoadedYet(true);
  }
  function handleError() {
    setLoadedYet(true);
    setError(true);
  }
  const imageStyle = {
    width: "100%",
    height: "100%",
    objectFit: "contain"
  }
  if (prevImgSrc === "none" || error) {
    // console.log("prev image:", prevImgSrc);
    // console.log("img src:", imgSrc);
    // console.log("error state:", error);
    // console.log("loaded yet:", loadedYet);
    return null;
  }
  else return (
    <div>
      <img src={prevImgSrc} style={imageStyle} alt="Could not load camera data" onLoad={handleLoad} onError={handleError}></img>
    </div>
  )
}

//A yellow, 'not connected' box with no relevant data displayed
function NotConnectedRoombox({ name, handleRedirect }) {
  return (
    <div className="box-container">
      <div className="room-box">
        {/* --TITLE-- */}
        <div className="row center-xs box-title yellowbg" onClick={handleRedirect}>
          <div className="col-xs-12 ">
            <h4>{name}</h4>
          </div>
        </div>
        <p style={{ textAlign: 'center', padding: 15 }}>
          ???
          <br />
          No Connection
        </p>
      </div>
    </div>
  )
}

//A box that displays room data, and wraps the above components
function Roombox(props) {

  //get hooks
  const navigate = useNavigate();
  const location = useLocation();
  function handleRedirect() {
    if (location.pathname !== `/room/${room.id}`) {
      navigate(`/room/${room.id}`);
    }
  }


  //configure the render
  const { room, darkMode } = props;

  if (room.type === "not_connected") {
    // can't communicate with crestron
    return <NotConnectedRoombox name={room.name} handleRedirect={handleRedirect} />
  } else {
    // we're connected
    let recordingIcon = null;
    // This is how the old CAENView does it - presumably, if leccap_recording is < 0, the room doesn't have one
    if (room.leccap_recording === 0) {
      recordingIcon = <i className="fas fa-video" style={{ color: "var(--box-title-bg)" }} data-tooltip-id="tooltip" data-tooltip-content="Not Recording"></i>
    } else if (room.leccap_recording > 0) {
      recordingIcon = <i className="fas fa-video" style={{ color: "var(--dark-blue" }} data-tooltip-id="tooltip" data-tooltip-content="Recording"></i>
    }

    //header background color
    let titleColor = "";

    switch (room.type) {
      case "conn": titleColor = "greybg"; break; // all good
      case "prob": titleColor = "redbg"; break; // problem button pressed, no one has responded
      case "av_issue": titleColor = "orangebg"; break; // AV subsystem issue (projector, audio)
      case "resp": titleColor = "greenbg"; break; // Problem button pressed, someone has responded
      default: console.error("Room number " + room.id + " has type " + room.type);
    }

    return (
      <div className="box-container">
        <div className="room-box">
          <div className={"row center-xs box-title " + titleColor} onClick={handleRedirect}>
            <div className="col-xs-12 ">
              <h4 style={(titleColor === " redbg" && !props.darkMode) ? { color: 'white' } : {}}>{room.name}</h4>
            </div>
          </div>
          <AVErrorRows room={room} darkMode={darkMode} />
          <RoomCamera imgSrc={props.imgSrc} />
          <div className="row box-contents">
            <div className={"col-xs-2 " + (room.ios ? "col-xs-offset-4" : "col-xs-offset-2") + " center-xs"}>
              <p>Pwr</p>
            </div>
            <div className="col-xs-2 center-xs">
              <p>Src</p>
            </div>
            <div className={"col-xs-2 " + (room.ios ? "col-xs-offset-2" : "col-xs-offset-4") + " center-xs"}>
              {recordingIcon}
            </div>
          </div>
          <MicRows room={room} darkMode={darkMode} />
          {room.ios
            ? <IOSRows room={room} />
            : <>
              <ProjectorRow room={room} number={1} />
              <ProjectorRow room={room} number={2} />
            </>}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  if (state.dataReceived) {
    return {
      room: state.rooms[ownProps.roomid],
      darkMode: state.darkMode
    }
  }
}

export default connect(mapStateToProps)(Roombox)