import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { setModal } from '../../helperFunctions/actions';
import VideoView from './VideoView';
import GridView from './GridView';


function ImageQuery(props) {
    const params = useLocation().pathname;
    const navigate = useNavigate();
    console.log("(ImageQuery) Location: ", params);
    console.log("(ImageQuery) Props; ", props);

    const { canViewImagequery, setModal } = props;
    useEffect(() => {
        console.log("imagequery");
        if (!canViewImagequery) {
            setModal({
                shown: true,
                header: "You're not authorized to view classroom snaps",
                paragraph: "If you think this is a mistake, please speak with CAEN administrative staff.",
                buttonText: "Back to CAENView",
                onClose: () => navigate('/')
            });
        }
        // eslint-disable-next-line
    }, [canViewImagequery, setModal]);


    if (!canViewImagequery) {
        return null;
    } else {
        // match.path should be "/viewimages"
        return (
            <Routes>
                <Route exact path={`${params}/video`} element={<VideoView/>} />
                <Route exact path={`${params}/grid`} element={<GridView/>} />
                <Route render={() => {<Navigate to={`${params}/video`} replace />}} />
            </Routes>
        )
    }
}

const mapStateToProps = (state) => ({ canViewImagequery: state.canViewImagequery });
const mapDispatchToActions = { setModal };
export default connect(mapStateToProps, mapDispatchToActions)(ImageQuery);