import { BrowserRouter as Router } from 'react-router-dom'
import { configureStore } from '@reduxjs/toolkit'
import rootReducer from './helperFunctions/rootReducer'
import { Provider } from 'react-redux'
import RenderTooltips from './components/Reusable/RenderTooltips'
import ProtectedRoutes from './components/Routing/ProtectedRoutes'
import SocketManager from './SocketManager'

export const rootStore = configureStore({reducer: rootReducer});

function App() {
  return (
    <Router>
      <Provider store={rootStore}>
        <RenderTooltips />
        <SocketManager />
        <ProtectedRoutes />
      </Provider>
    </Router>
  )
}

export default App