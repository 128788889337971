import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from '../../helperFunctions/withRouter';
import { updateActiveFilters, updateShownRooms } from '../../helperFunctions/actions';

function SidebarStatus(props) {
  const navigate = useNavigate();
  const location = useLocation();

  const updateFilters = (value) => {
    const [statusFilter, typeFilter, buildingFilter] = props.activeFilters;
    if (value !== statusFilter) {
      props.updateActiveFilters([value, typeFilter, buildingFilter]);
      props.updateShownRooms();
    }

    //redirect to home if we're on another page (like roominfo)
    if(location.pathname !== '/') { 
      navigate('/');
    }
  };

  const category = (name) => {
    // roomStatus.AllRooms is just roomStatus['All Rooms'] without the space
    let number = props.displayRoomStatus[name.replace(/ /g,'')];
    let needsAttnStyle = (name === "Needs Attention") ? {backgroundColor:"var(--red)", color:"white"}:{};
    return (
      <label className={(name === props.activeFilters[0]) ? "list-group-item selected":"list-group-item"} key={name}>
        <input type="radio" checked={name === props.activeFilters[0]} name="radio-room" onClick={() => updateFilters(name)} onChange={()=>{}}/>
        <span className="radiobtn"></span>
        <span className="list-item-name">{name}</span>
        <span className="list-item-number" style={needsAttnStyle}>{number}</span>
      </label>
    )
  }

  return (
    <div>
      <div className="sidebar-category">
        <ul>
          <li className="list-title">
            <h2>Room Status</h2>
          </li>
          {category("All Rooms")}
          {category("Needs Attention")}
          {category("Projectors On")}
          {category("Mics Not Charged")}
          {category("Currently Recording")}
        </ul>
      </div>
    </div>
  );
}

function mapStateToProps(state) { return {
    activeFilters: state.activeFilters,
    displayRoomStatus: state.displayRoomStatus
  }
}
const mapDispatchToActions = { updateActiveFilters, updateShownRooms };
export default withRouter(connect(mapStateToProps, mapDispatchToActions)(SidebarStatus))