import React, { useState, useEffect, useReducer } from 'react';
import ModernDatepicker from 'react-modern-datepicker';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import './ImageQueryStyles.css';
import SnapWindow from './SnapWindow';
import formatDate from '../../helperFunctions/formatDate';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import { withRouter } from '../../helperFunctions/withRouter';
import { Tooltip } from 'react-tooltip';

const MAX_STORED_SNAPS = 720; // 2hrs at 6 snaps per minute

function RoomListOptions({ rooms }) {
  return rooms.map((room) => (
    <option key={room.id} value={room.name} />
  )
  )
}

const VideoImageQuery = () => {
  // ALL TO REPLICATE CALLBACK FOR SETSTATE this feels really hacky...
  const reduceState = (state, action) => {
    if (action.signal && action.setSignal) {
      action.setSignal(true);
    }
    if (action.newState) {
      return { ...state, ...action.newState };
    }
    console.log("VideoImageQuery Error: FAILED TO SET STATE");
    return { ...state };
  };

  // Call Hooks
  const navigate = useNavigate();
  const location = useLocation();
  const [state, dispatch] = useReducer(reduceState, {
    roomSnaps: [],
    selectedDate: moment().format("MMMM DD, YYYY"),
    selectedTime: moment(new Date()),
    selectedRoom: "",
    rooms: [],
    minTime: null,
    maxTime: null,
    awaitingSnaps: {
      waiting: false,
      location: '',
      room: null
    },
    gotInitialSnaps: false,
    moreSnaps: false,
    errorMsg: "",
    timepickerOpen: false,
  });
  const [signalRequestInitialSnaps, setSignal] = useState(false);

  useEffect(() => {
    const requestInitialSnaps = () => {
      if (state.awaitingSnaps.waiting) {
        dispatch({ newState: { ...state, errorMsg: 'Loading...' } });
        return;
      }

      const indexOfRoom = state.rooms.map(room => room.name).indexOf(state.selectedRoom);
      if (indexOfRoom === -1) {
        dispatch({ newState: { ...state, errorMsg: "That's an invalid room." } });
        return;
      } else if (!state.selectedDate || !state.selectedTime) {
        dispatch({ newState: { ...state, errorMsg: "Please enter a time." } });
        return;
      }

      dispatch({ newState: { ...state, gotInitialSnaps: false } });

      // initial image grab
      // get month, day from input
      let newTime = new Date(state.selectedDate);
      // add the hours/minutes time on
      const selectedTime = new Date(state.selectedTime);
      newTime.setHours(newTime.getHours() + selectedTime.getHours());
      newTime.setMinutes(newTime.getMinutes() + selectedTime.getMinutes());

      const MINUTES_PADDING = 30;
      window.g_socket.emit('get-ip-image-urls', {
        time: newTime,
        roomId: state.rooms[indexOfRoom].id,
        duration: MINUTES_PADDING * 60
      });
      dispatch({ newState: { ...state, awaitingSnaps: { waiting: true, location: 'initial', room: state.rooms[indexOfRoom].id } } });
    }

    if (!signalRequestInitialSnaps) {
      return;
    }
    requestInitialSnaps();
    setSignal(false);
  }, [signalRequestInitialSnaps, state]);

  useEffect(
    () => {
      // ComponentDidMount
      window.g_socket.emit('get-image-roomlist');

      window.g_socket.on('ip-image-urls', (data) => {
        let newSnaps = [];
        let starterKey;

        switch (state.awaitingSnaps.location) {
          case 'start': starterKey = state.roomSnaps[0].key - data.snapList.length; break;
          case 'end': starterKey = state.roomSnaps[state.roomSnaps.length - 1].key + 1; break;
          case 'initial': starterKey = 0; break;
          default:
        }

        for (let i = 0; i < data.snapList.length; i++) {
          const snap = data.snapList[i];
          const time = snap.time;
          const url = `https://s3.amazonaws.com/caenview-prod/${snap.filename}`
          const key = starterKey + i;
          // preload image
          new Image().src = url;
          newSnaps.push({ time, url, key })
        }

        // assign state after assembling object
        const newState = {};
        newState.errorMsg = "";

        // check if we're out of snaps
        if (newSnaps.length > 0) {
          // check if we've these are initial snaps
          if (state.awaitingSnaps.location !== 'initial') {
            // find out where snaps are (beginning or end)
            const oldSnaps = state.roomSnaps;
            if (state.awaitingSnaps.location === 'start') {
              if (oldSnaps.length + newSnaps.length > MAX_STORED_SNAPS) {
                newState.roomSnaps = newSnaps.concat(oldSnaps.slice(0, oldSnaps.length - newSnaps.length));
              } else {
                newState.roomSnaps = newSnaps.concat(oldSnaps);
              }
            } else if (state.awaitingSnaps.location === 'end') {
              if (oldSnaps.length + newSnaps.length > MAX_STORED_SNAPS) {
                newState.roomSnaps = oldSnaps.slice(newSnaps.length).concat(newSnaps);
              } else {
                newState.roomSnaps = oldSnaps.concat(newSnaps);
              }
            }

            newState.minTime = new Date(newState.roomSnaps[0].time);
            newState.maxTime = new Date(newState.roomSnaps[newState.roomSnaps.length - 1].time);
          } else {
            // initial snaps grab
            newState.roomSnaps = newSnaps;
            newState.minTime = new Date(newSnaps[0].time);
            newState.maxTime = new Date(newSnaps[newSnaps.length - 1].time);
            newState.gotInitialSnaps = true;
          }
          newState.moreSnaps = true;
        } else {
          newState.moreSnaps = false;
          newState.errorMsg = "We couldn't find any " + (state.gotInitialSnaps ? "" : "more ") + "snaps in that range.";
        }
        newState.awaitingSnaps = { waiting: false, location: '', room: state.awaitingSnaps.room };
        dispatch({ newState: { ...state, ...newState } });
      });

      window.g_socket.on('image-roomList', (data) => {
        dispatch({ newState: { ...state, rooms: data } });

        if (location.state) {
          const { roomName, time } = location.state;

          if (data.some(room => (room.name === roomName)) && (time instanceof Date) && !isNaN(time.getTime())) {
            // valid room name and time
            dispatch({ signal: true, setSignal: setSignal, newState: { ...state, selectedRoom: roomName, selectedDate: moment(time).format("MMMM DD, YYYY"), selectedTime: moment(time) } });
          } else {
            console.error("Malformed location state to jump to time:", location.state);
          }
        }
      });

      // ComponentWillUnmount
      return () => {
        window.g_socket.removeAllListeners('ip-image-urls');
        window.g_socket.removeAllListeners('image-roomList');
      };
    }, [location.state, state]);

  const handleGoButtonClick = () => {
    dispatch({ signal: true, setSignal: setSignal });
  }

  const requestMoreSnaps = (location) => {
    //check validity of request
    if (state.awaitingSnaps.waiting) {
      if (state.errorMsg !== "Loading...") dispatch({ newState: { ...state, errorMsg: 'Loading...' } });
      return;
    }

    const ONE_MINUTE = 60000; // milliseconds
    const MINUTES_PADDING = 5;
    let newTime;
    if (location === 'start') {
      newTime = new Date(state.minTime.getTime() - ONE_MINUTE * MINUTES_PADDING); // 5mins before minimum time, with 5mins padding
    } else if (location === 'end') {
      newTime = new Date(state.maxTime.getTime() + ONE_MINUTE * MINUTES_PADDING); // 5mins after maximum time, with 5mins padding
    }

    window.g_socket.emit('get-ip-image-urls', {
      time: newTime,
      roomId: state.awaitingSnaps.room,
      duration: MINUTES_PADDING * 60
    });

    dispatch({ newState: { ...state, awaitingSnaps: { waiting: true, location: location, room: state.awaitingSnaps.room } } });
  }

  const goToGridView = () => {
    // the View change button's tooltip needs to know to hide
    Tooltip.hide();
    navigate("/viewimages/grid");
  }

  const datepickerChanged = (val) => {
    if (val !== "Invalid Date") {
      dispatch({ newState: { ...state, selectedDate: val, timepickerOpen: true } });
    }
  }

  return (
    <div className="rooms-container single-view">
      <div className="row between-xs">
        <div className="col-xs-3" id="left-column-imagequery">
          <div className="imagequery-top-box">
            {state.awaitingSnaps.room
              ? <h1 className="imagequery-room-title">{state.rooms[state.rooms.map(room => room.id).indexOf(state.awaitingSnaps.room)].name}</h1>
              : <h1>View Room Snaps</h1>}
            {state.gotInitialSnaps &&
              <div>
                <h2>{formatDate(state.minTime)} to </h2><br />
                <h2>{formatDate(state.maxTime)}</h2>
              </div>}
            {state.rooms.length !== 0 &&
              <div>
                <input autoFocus className="text-input" type="text" list="imagequery-room-list" value={state.selectedRoom} onChange={e => dispatch({ newState: { ...state, selectedRoom: e.target.value } })} />
                <datalist id="imagequery-room-list" >
                  <RoomListOptions rooms={state.rooms} />
                </datalist>
              </div>}
          </div>
          <div className="imagequery-middle-box">
            <h2>Date and Time:</h2>
            <div className="date-time-pickers-container">
              <div className="datepicker">
                <ModernDatepicker
                  date={state.selectedDate}
                  onChange={datepickerChanged}
                  placeholder="Select a date..."
                  format="MMMM DD, YYYY"
                  primaryColor="var(--a-main-bg)"
                  secondaryColor="var(--main-bg)"
                  primaryTextColor="var(--info-text)"
                  secondaryTextColor="var(--a-info-text)"
                />
              </div>
              <div className="timepicker">
                <TimePicker
                  value={state.selectedTime}
                  onChange={val => dispatch({ newState: { ...state, selectedTime: val } })}
                  open={state.timepickerOpen}
                  onOpen={() => dispatch({ newState: { ...state, timepickerOpen: true } })}
                  onClose={() => dispatch({ newState: { ...state, timepickerOpen: false } })}
                  showSecond={false}
                  format="h:mm a"
                  use12Hours
                  inputReadOnly
                  onAmPmChange={() => dispatch({ newState: { ...state, timepickerOpen: false } })}
                />
              </div>
            </div>
            {(state.selectedDate && state.selectedTime && state.rooms.some(room => (room.name === state.selectedRoom)))
              && <div className="button imagequery-go-button fade-in" onClick={handleGoButtonClick}>Go!</div>}
            <div className="error-msg"><h2>{state.errorMsg}</h2></div>
          </div>
          <div className="imagequery-bottom-box">
            <h2>View:</h2>
            <div className="row view-container">
              <div
                className="button col-sm center-xs active-view-button"
                data-tooltip-id="tooltip"
                data-tooltip-content="View snaps as a video feed"
              ><i className="fa fa-video"></i></div>
              &nbsp;
              <div
                className="button col-sm center-xs"
                onClick={goToGridView}
                data-tooltip-id="tooltip"
                data-tooltip-content="View snaps as a grid of snaps"
              ><i className="fa fa-th"></i></div>
            </div>
          </div>
        </div>
        <div className="col-xs-9" id="right-column">
          {state.gotInitialSnaps && <SnapWindow roomSnaps={state.roomSnaps} requestMoreSnaps={requestMoreSnaps} sliderConfig={{ min: state.roomSnaps[0].key, max: state.roomSnaps[state.roomSnaps.length - 1].key }} />}
        </div>
      </div>
    </div>
  )
};


export default withRouter(VideoImageQuery);