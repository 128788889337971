import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import MenuKey from './MenuKey';
import MenuSettings from './MenuSettings';
import NoticeBar from './NoticeBar';
import { withRouter } from '../../helperFunctions/withRouter';
import { setReduxState, updateShownRooms, toggleCookie } from '../../helperFunctions/actions';
import RefreshIcon from './RefreshIcon';

function Topbar(props) {
  const [selected, setSelected] = useState("none");
  const [popupHover, setPopupHover] = useState(false);
  const [justClickedIcon, setJustClickedIcon] = useState(false);
  const [showMobileSettings, setShowMobileSettings] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  window.onclick = () => {
    if (justClickedIcon) {
      setJustClickedIcon(false)
    } else {
      if (!popupHover && selected !== "none") {
        setSelected("none");
      }
    }
  };

  const handleSearch = (event) => {
    event.preventDefault();
    if ( location.pathname !== '/') navigate('/');
    props.setReduxState({ searchQuery: event.target.value });
    props.updateShownRooms();
  };

  const togglePopup = (popupName) => {
    if (selected === popupName) {
      setSelected("none")
    } else {
      setSelected(popupName)
    }
    setJustClickedIcon(true)
  };

  const renderPopups = () => {
    if (selected) {
      let popup;
      switch (selected) {
        case "info": popup = <MenuKey />; break;
        case "settings": popup = <MenuSettings togglePopup={togglePopup} />; break;
        default: popup = null;
      }

      return <div onMouseEnter={() => setPopupHover(true)} onMouseLeave={() => setPopupHover(false)}>
        {popup}
      </div>
    }
  };


  // Unsure about renderPopups() and togglePopup() functions
  return (
    <div>
      {renderPopups()}
      <nav className="navbar" role="navigation">
        <div className="navbar-header">
          <h1><Link className="navbar-brand" to="/">CAENView</Link></h1>
        </div>

        <div className="navbar-search">
          <input className="navbar-input" id="menu-search" type="text" placeholder="Search for rooms..." onChange={handleSearch} autoComplete="off" />
          <span className="search-icon" onClick={() => document.getElementById("menu-search").focus()}> {/*clicking on search icon focuses searchbox*/}
            <i className="fas fa-search"></i>
          </span>
        </div>
        <div className="navbar-burger">
          <div className="navbar-icon" onClick={() => setShowMobileSettings(!showMobileSettings)}>
            <i className="fa fa-bars"></i>
          </div>
        </div>

        <div className={showMobileSettings ? "navbar-icons expanded" : "navbar-icons"}>
          {/* REFRESH ICON */}
          {props.showImages &&
            <span className="navbar-icon" onClick={() => props.toggleCookie('refreshImages')}>
              {props.refreshImages
                ? <RefreshIcon spin={props.spinRefreshIcon} data-tooltip-id="tooltip" data-tooltip-content="Turn Off Camera Refreshing" color="var(--light-blue)" />
                : <RefreshIcon data-tooltip-id="tooltip" data-tooltip-content="Turn On Camera Refreshing" color="#FFFFFF" />
              }
            </span>
          }
          {/* IMAGE ICON */}
          <span className="navbar-icon" onClick={() => props.toggleCookie('showImages')}>
            {props.showImages
              ? <i className="fas fa-image" data-tooltip-id="tooltip" data-tooltip-content="Hide Room Cameras" style={{ color: 'var(--light-blue)' }}></i>
              : <i className="fas fa-image" data-tooltip-id="tooltip" data-tooltip-content="Show Room Cameras"></i>
            }
          </span>
          {/* SOUND ICON */}
          <span className="navbar-icon" onClick={() => props.toggleCookie('soundOn')}>
            {props.soundOn
              ? <i className="fas fa-volume-up" data-tooltip-id="tooltip" data-tooltip-content="Turn Sound Off"></i>
              : <i className="fas fa-volume-mute" data-tooltip-id="tooltip" data-tooltip-content="Turn Sound On"></i>
            }
          </span>
          {/* CALENDAR ICON */}
          <span className="navbar-icon" onClick={() => navigate('/calendargrid')}>
            <i className="fas fa-calendar-alt"></i>
          </span>
          {/* MENU KEY ICON */}
          <span className="navbar-icon" onClick={() => togglePopup("info")}>
            <i className="fas fa-info-circle"></i>
          </span>
          {/* SETTINGS ICON */}
          <span className="navbar-icon" onClick={() => togglePopup("settings")}>
            <i className="fas fa-cog"></i>
          </span>
          <div className="navbar-user" onClick={() => togglePopup("settings")}>
            <h2> <span>{props.nickname}</span> </h2>
            <span className="uniqname">({props.uniqname})</span>
          </div>
        </div>
      </nav>
      <NoticeBar />
    </div>
  )
}

// let the component listen to just a few props that dierctly affect it 
function mapStateToProps(state) {
  return {
    nickname: state.nickname,
    uniqname: state.uniqname,
    soundOn: state.soundOn,
    showImages: state.showImages,
    refreshImages: state.refreshImages,
    activeFilters: state.activeFilters,
    spinRefreshIcon: state.spinRefreshIcon
  }
}
//give component these functions to modify Redux store
const mapDispatchToActions = { setReduxState, updateShownRooms, toggleCookie };
export default withRouter(connect(mapStateToProps, mapDispatchToActions)(Topbar))