import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from '../../helperFunctions/withRouter';
import { updateActiveFilters, updateShownRooms } from '../../helperFunctions/actions';

function SidebarRoomType(props) {
  const navigate = useNavigate();
  const location = useLocation();

  const updateFilters = (value) => {
    const [statusFilter, typeFilter, buildingFilter] = props.activeFilters;
    // make a modifiable typeFilter copy
    let newTypeFilter = [...typeFilter];
    switch(value) {
      case "CoE Classroom": newTypeFilter[0] = (newTypeFilter[0] === "" ? "CoE Classroom":"");
        break;
      case "CAEN Lab": newTypeFilter[1] = (newTypeFilter[1] === "" ? "CAEN Lab":"");
        break; 
      case "Conference Room": newTypeFilter[2] = (newTypeFilter[2] === "" ? "Conference Room":"");
        break;
      default: console.error(value, ' is not a room type');
    }
    props.updateActiveFilters([statusFilter, newTypeFilter, buildingFilter]);
    props.updateShownRooms();

    //redirect to home if we're on another page (like roominfo)
    if(location.pathname !== '/') { 
      navigate('/');
    }
  }

  const category = (name) => {
    const { displayRoomTypes } = props;
    // displayRoomTypes.CoEClassroom is just displayRoomTypes['CoE Classroom'] without the ' '
    const number = displayRoomTypes[name.replace(/ /g,'')];
    return (
      <label className={props.activeFilters[1].includes(name) ? "list-group-item selected":"list-group-item"} key={name}> 
        <input 
          type="checkbox" 
          defaultChecked={props.activeFilters[1].includes(name)} 
          onChange={() => updateFilters(name)}
        />
        <span className="checkmark"></span>
        <span className="list-item-name">{name}</span>
        <span className="list-item-number">{number}</span>
      </label>
    )
  }

  return (
    <div>
      <div className="sidebar-category">
        <ul>
          <li className="list-title">
            <h2>Room Type</h2>
          </li>
          {category("CoE Classroom")}
          {category("CAEN Lab")}
          {category("Conference Room")}
        </ul>
      </div>
    </div>
  );
}

function mapStateToProps(state) { return {
    activeFilters: state.activeFilters,
    displayRoomTypes: state.displayRoomTypes,
    intersectedRooms: state.intersectedRooms
  }
}
const mapDispatchToActions = { updateActiveFilters, updateShownRooms };
export default withRouter(connect(mapStateToProps, mapDispatchToActions)(SidebarRoomType))