import React, { useState, useRef, useEffect } from 'react';
import Roombox from './Roombox'
import FilterWarningBar from './FilterWarningBar'
import { connect } from 'react-redux';
import { setReduxState } from '../../helperFunctions/actions'


function SearchDescription( {searchQuery, buildingFilter} ) {
  return (
    <div className="search-description">
      {searchQuery && <p>Showing results for <strong>{searchQuery.toUpperCase()}</strong>
      {(buildingFilter !== "All Buildings") && <span>, filtering by <strong>{buildingFilter}</strong></span>}
      </p>}
    </div>
  )
}

const RoomMenu = (props) => {
  const [lastRefresh, setLastRefresh] = useState(new Date().getTime());
  const cameraRefreshInterval = useRef(null);
  const mounted = useRef(false);
  const showImages = useRef(props.showImages);
  const refreshImages = useRef(props.refreshImages);

  const updateCameras = () => {
    props.setReduxState( { spinRefreshIcon: true } );
    setLastRefresh(new Date().getTime());
    setTimeout(() => {
      props.setReduxState( { spinRefreshIcon: false } );
    }
    , 1000)
  };

  const startCameraRefresh = () => {
    const REFRESH_SPEED = 15000;
    cameraRefreshInterval.current = setInterval(() => {
      if(refreshImages.current) {
        updateCameras();
      } else {
      }
    }, REFRESH_SPEED, refreshImages.current);
  };

  useEffect(() => {
    mounted.current = true;

    startCameraRefresh();

    return () => {
      if(cameraRefreshInterval.current) {
        clearInterval(cameraRefreshInterval.current);
        cameraRefreshInterval.current = null;
      }
    }
  },[]);

  useEffect(() => {
    if(props.refreshImages !== refreshImages.current) {
      refreshImages.current = props.refreshImages;
      if(props.refreshImages) {
        updateCameras();
      }
    }
  });

  useEffect(() => {
    if(props.showImages === true && showImages.current === false) {
      console.log('showing images update cameras');
      updateCameras();
      showImages.current = true;
    } else if(props.showImages === false && showImages.current === true){
      showImages.current = false;
    } else {
      showImages.current = props.showImages;
    }
  });


  let rooms = props.intersectedRooms.map(id => (
    <Roombox 
      roomid={id} 
      key={id} 
      imgSrc={(props.showImages && mounted.current)
        ? `/room/${id}/camera/jpeg?${lastRefresh}`
        : 'none'
      }/>
    ));
  return (
    <div style={{width:'100%'}}>
      <FilterWarningBar />
      <div className="rooms-container">
        <SearchDescription searchQuery={props.searchQuery} buildingFilter={props.activeFilters[2]} />
        <div className="rooms-display"> 
            {rooms}
        </div>
      </div>
    </div>
  );
}

// watch only the props we need
function mapStateToProps(state) {
  return {
    intersectedRooms: state.intersectedRooms,
    showImages: state.showImages,
    refreshImages: state.refreshImages,
    searchQuery: state.searchQuery,
    activeFilters: state.activeFilters
  }
}

const mapDispatchToActions = { setReduxState };
export default React.memo(connect(mapStateToProps, mapDispatchToActions)(RoomMenu));