import React from 'react';
import { withRouter } from '../../helperFunctions/withRouter';
import formatDate from '../../helperFunctions/formatDate';

class LectureCapture extends React.Component {

  render() {
    //set these to false and modify them as we go
    let showRecordingInfo = false, showNextInfo = false, isScheduled = false;
    let statusMessage = "", nextInfo = "";
    const { leccapData } = this.props;
    if(leccapData === undefined) statusMessage = "Not working at the moment";
    else if(leccapData.api_id < 0) statusMessage = "Not Installed";
    else {
      if(leccapData.recording > 0)
      {
        showRecordingInfo = true;
        isScheduled = (leccapData.scheduled > 0)

        if(isScheduled) statusMessage = "Recording (Scheduled)";
        else statusMessage = "Recording (Unscheduled)";
      }
      else
      {
        showNextInfo = true;
        statusMessage = "Not Recording";

        if(leccapData.next === -1) {
          nextInfo = "No scheduled recording";
        } else {
          if(leccapData.next) {
            nextInfo = formatDate(leccapData.next);
           } else {
            nextInfo = "Not yet fetched";
           }
        }
      }
    }
      
    
    return (
      <div className="leccap">
        <div className="room-box">
          <div className="row center-xs box-title greybg">
            <div className="col-xs-12">
              <h4>Lecture Capture System {(leccapData && leccapData.api_id >= 0) && 
                  <a href={"https://leccap.engin.umich.edu/leccap/admin/recorder/" + leccapData.api_id} style={{pointerEvents: "auto"}}>
                    <i className="fa fa-share"></i> {/* ARROW LINK ICON */}
                  </a>
                }
              </h4>
            </div>
          </div>
          {showNextInfo &&
          <div className="row box-contents">
            <div className="col-xs-5">
              <h4>Next Recording:</h4>
            </div>
            <div className="col-xs-7">
              <p>{nextInfo}</p>
            </div>
          </div>}
          <div className="row box-contents">
            <div className="col-xs-5">
              <h4>Status:</h4> 
            </div>
            <div className="col-xs-7">
              <p>{statusMessage}</p>
            </div>
          </div>
          {showRecordingInfo && leccapData &&
          <div className="row box-contents">
            <div className="col-xs-5">
              <h4>Site:</h4>
            </div>
            <div className="col-xs-7">
              <a href={"https://leccap.engin.umich.edu/leccap/manage/site/recordings/" + leccapData.site}>{leccapData.site}</a>
            </div>
          </div>}
          {showRecordingInfo && leccapData &&
          <div className="row box-contents">
            <div className="col-xs-5">
              <h4>Camera:</h4>
            </div>
            <div className="col-xs-7">
              <a href={`https://leccap.engin.umich.edu/leccap/manage/site/dorecord/${leccapData.site}/${leccapData.api_id}}`}>Link</a>
            </div>
          </div>}
          {showRecordingInfo && leccapData &&
          <div className="row box-contents">
            <div className="col-xs-5">
              <h4>Start:</h4>
            </div>
            <div className="col-xs-7">
              <p>{formatDate(leccapData.start)}</p>
            </div>
          </div>}
          {isScheduled && leccapData &&
          <div className="row box-contents">
            <div className="col-xs-5">
              <h4>End:</h4> 
            </div>
            <div className="col-xs-7">
              <p>{formatDate(leccapData.end)}</p>
            </div>
          </div>}
        </div>
      </div>
    );
  }

}

export default withRouter(LectureCapture)