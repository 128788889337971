import React, { useLayoutEffect } from 'react';
import { Tooltip } from 'react-tooltip';
import { connect } from 'react-redux';
import { withRouter } from '../../helperFunctions/withRouter';
import isMobile from '../../helperFunctions/isMobile';

function RenderTooltips(props) {
  useLayoutEffect(() => {
    //after render, rebuild the tooltips so components that just mounted have tooltips.
    // Tooltip.rebuild();
  }, [props])

  
  
  if(isMobile()) return null; //no tooltips on mobile devices
  return (
    <Tooltip id="tooltip" place="bottom" multiline={true} style={{textAlign: "center"}} effect="solid" textColor="#EFEFEF"/> 
  )
}

// when intersectedRooms changes, a hidden roombox might have shown
// connect() to it to watch it
// showImages shows a refresh button that might have been hidden, watch that too
const mapStateToProps = (state) => ({
  intersectedRooms: state.intersectedRooms,
  showImages: state.showImages
})
export default withRouter(connect(mapStateToProps)(RenderTooltips))