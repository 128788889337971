import React from 'react';
import { Collapse } from 'react-collapse';
import { connect } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { withRouter } from '../../helperFunctions/withRouter';
import { updateActiveFilters, updateShownRooms, toggleCookie } from '../../helperFunctions/actions';

const SidebarBuildings = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const updateFilters = (value) => {
    const [statusFilter, typeFilter, buildingFilter] = props.activeFilters;

    if (value !== buildingFilter) {
      props.updateActiveFilters([statusFilter, typeFilter, value]);
      props.updateShownRooms();
    }

    if (location.pathname !== '/') {
      navigate('/');
    }
  };

  // don't think that on change is needed.
  const categories = props.buildings.map(category => (
    <label className={(category.name === props.activeFilters[2]) ? "list-group-item selected" : "list-group-item"} key={category.name}>
      <input type="radio" name="radio" checked={category.name === props.activeFilters[2]} onClick={() => updateFilters(category.name)} onChange={() => { }} />
      <span className="radiobtn"></span>
      <span className="list-item-name">{category.name}</span>
      <span className="list-item-number">{category.rooms.length}</span>
    </label>
  ));

  const chevron = (props.showBuildings) ? <i className="fas fa-chevron-down" /> : <i className="fas fa-chevron-up" />;

  return (
    <div>
      <div className="sidebar-category">
        <ul>
          <li className="list-title accordion" onClick={() => props.toggleCookie("showBuildings")}>
            <h2>Building</h2>
            {chevron}
          </li>
          <Collapse isOpened={props.showBuildings}>
            {categories}
          </Collapse>
        </ul>
      </div>
      <p style={{ fontSize: "12px" }}>Last modified: {props.lastModifiedDate}</p>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    activeFilters: state.activeFilters,
    buildings: state.buildings,
    showBuildings: state.showBuildings,
    lastModifiedDate: state.lastModifiedDate
  }
}

const mapDispatchToActions = { updateActiveFilters, updateShownRooms, toggleCookie };
export default withRouter(connect(mapStateToProps, mapDispatchToActions)(SidebarBuildings))